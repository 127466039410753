import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useQueryParam } from "gatsby-query-params";

import styles from "./share-page.module.css";
import Layout from "./layout";
import SEO from "./seo";

const BULLET = "•";
const APP_NAME = "Rádio Tuga";

const STATIONS_URL = {
  pt: "https://radiotuga.herokuapp.com/stations/def/debug",
  fr: "https://radiotuga.herokuapp.com/stations/francedef/debug",
  br: "https://radiotuga.herokuapp.com/stations/brasildef/debug",
  de: "https://radiotuga.herokuapp.com/stations/germanydef/debug",
};
const DOWNLOAD_URL = {
  pt: "/portugal",
  fr: "/france",
  br: "/brasil",
  de: "/germany",
};

async function getStation(countryCode, sid) {
  const url = STATIONS_URL[countryCode];
  if (!url) {
    throw new Error("url not found");
  }

  const response = await fetch(url, {
    mode: "cors",
    headers: {
      token: "EDDG",
    },
  });
  const data = await response.json();
  if (data && data.stations && data.stations.length > 0) {
    const station = data.stations.find(s => s.id === sid);
    return station || null;
  }
  return null;
}

const SharePage = ({ countryCode }) => {
  const sid = useQueryParam("sid", null);
  const title = useQueryParam("title", null);
  const artist = useQueryParam("artist", null);

  const [station, setStation] = useState(null);

  useEffect(() => {
    if (sid === null) {
      return;
    }

    getStation(countryCode, sid)
      .then(station => {
        setStation(station);
      })
      .catch(err => {
        console.log("err", err);
      });
  }, [sid, countryCode]);

  let what = APP_NAME;
  if (station) {
    what =
      title && artist
        ? `${title} ${BULLET} ${artist} na ${station.name}`
        : `${station.name} na ${APP_NAME}`;
  } else if (title && artist) {
    what = `${title} ${BULLET} ${artist} na ${APP_NAME}`;
  }

  return (
    <Layout countryCode={countryCode}>
      <SEO title="Share" />
      <section className="mb-8">
        <h1 className="text-xl leading-snug text-white md:text-center">
          Estou a ouvir {what}
        </h1>
        {station !== null && (
          <div className="flex items-center justify-center my-8">
            <img
              className="inline-block mr-4"
              width="100"
              height="100"
              src={station.img}
              alt="Station"
            ></img>
            <aside className="inline-block">
              <h2 className={styles.stationName}>{station.name}</h2>
              <h3 className={styles.stationDescription}>{station.desc}</h3>
            </aside>
          </div>
        )}
      </section>
      <Link to={DOWNLOAD_URL[countryCode]}>
        <div className={styles.downloadBtn}>Download</div>
      </Link>
    </Layout>
  );
};

export default SharePage;
